import i18next from 'i18next';
import i18nextXHRBackend from 'i18next-xhr-backend';

const i18nextCore = (locale, baseUrl, cb) => {
  return i18next.use(i18nextXHRBackend).init(
    {
      lng: locale,
      fallbackLng: 'en',
      keySeparator: '$',
      interpolation: {
        escapeValue: false,
      },
      backend: {
        loadPath: `${baseUrl}assets/locale/messages_{{lng}}.json`,
        crossDomain: true,
      },
    },
    cb,
  );
};

const i18n = ({
  locale,
  baseUrl,
}) => {
  return new Promise((resolve, reject) => {
    const i18nInstance = i18nextCore(
      locale,
      baseUrl,
      (err, t) => (err ? reject(err) : resolve({ t, i18nInstance })),
    );
  });
};

export class Translations {
  constructor() {
    this.init = this.init.bind(this);
  }

  init(locale, baseUrl) {
    return i18n({ locale, baseUrl }).then(({ t }) => {
      this._t = t;
    });
  }

  t(key, options = {}) {
    if (!this._t) {
      throw new Error('Translation service was not initialized yet!');
    }
    return this._t(key, options);
  }
}
