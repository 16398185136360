import {withMembersArea, integrateMembersAreaApps} from '@wix/members-area-integration-kit';
import {Translations} from './translations.js';

/* eslint-disable */
const getSharedGalleryEditorApi = function () {
  var photoSharingAppDefId = '476dc2dd-e16e-43b0-a0aa-1a486c78fbe2';

  var PHOTO_SHARING_APP_DEF_IDS = {
    // Production
    '476dc2dd-e16e-43b0-a0aa-1a486c78fbe2': {
      membersAreaPageComponentId: 'Gallery',
      membersAreaUrlOverride: 'gallery'
    }
  };


  function parseStaticsUrlFromEditorScriptUrl(editorScriptUrl) {
    const editorScriptUrlParts = editorScriptUrl.split('/');
    const staticsBaseUrl = editorScriptUrlParts.slice(0, editorScriptUrlParts.length - 1).join('/') + '/';
    return staticsBaseUrl;
  }

  var membersAppDefId = '14cc59bc-f0b7-15b8-e1c7-89ce41d0e0c9';
  var allMembersAppDefId = '14dbefd2-01b4-fb61-32a7-3abd44da4908';

  const getAboutPage = (t, staticsUrl) => ({
    appDefinitionId: '14dbef06-cc42-5583-32a7-3abd44da4908',
    pageId: 'about',
    method: 'addApplication',
    loginMenuTitle: 'Profile',
    showInLoginMenu: true,
    menuOrder: 1,
    socialHome: true,
    urlOverride: 'profile',
    page: {
      name: t('about_page_name'),
      shortDescription: t('about_page_short_description'),
      description: t('about_page_description'),
      iconURL: `${staticsUrl}assets/editor-images/members-area-icon.svg`,
      origin:  t('about_origin'),
      screenshots: [
        `${staticsUrl}assets/editor-images/profile.png`,
        `${staticsUrl}assets/editor-images/mobile-profile.png`,
      ]
    }
  });

  const getFollowersPage = (t, staticsUrl) => ({
    appDefinitionId: '14ebe801-d78a-daa9-c9e5-0286a891e46f',
    pageId: 'following_followers',
    method: 'addApplication',
    showInMemberMenu: false,
    menuOrder: 2,
    urlOverride: 'followers',
    page: {
      name: t('followers_page_name'),
      shortDescription: t('followers_page_short_description'),
      description: t('followers_page_description'),
      iconURL: `${staticsUrl}assets/editor-images/members-area-icon.svg`,
      origin:  t('followers_origin'),
      screenshots: [
        `${staticsUrl}assets/editor-images/followers.png`,
        `${staticsUrl}assets/editor-images/mobile-followers.png`,
      ]
    }
  });

  const getNotificationsPage = (t, staticsUrl) => ({
    appDefinitionId: '14f25924-5664-31b2-9568-f9c5ed98c9b1',
    pageId: 'notifications_app',
    numbers: {
      key: 'notificationsCount',
      default: 0
    },
    method: 'addApplication',
    menuOrder: 4,
    social: false,
    urlOverride: 'notifications',
  });

  const getSettingsPage = (t, staticsUrl) => ({
    appDefinitionId: '14f25dc5-6af3-5420-9568-f9c5ed98c9b1',
    pageId: 'settings',
    method: 'addApplication',
    menuOrder: 4,
    social: false,
    urlOverride: 'settings',
  });

  const getAllMembersPage = () => ({
    method: 'addApplicationToSite',
    appDefinitionId: allMembersAppDefId
  });

  const getGalleryPage = (t, staticsUrl) => ({
    method: 'addSection',
    appDefinitionId: photoSharingAppDefId,
    pageId: PHOTO_SHARING_APP_DEF_IDS[photoSharingAppDefId].membersAreaPageComponentId,
    urlOverride: PHOTO_SHARING_APP_DEF_IDS[photoSharingAppDefId].membersAreaUrlOverride,
    social: true,
    menuOrder: 2,
    page: {
      name: t('gallery_page_name'),
      shortDescription: t('gallery_page_short_description'),
      description: t('gallery_page_description'),
      iconURL: `${staticsUrl}assets/editor-images/shared-gallery-icon.png`,
      origin:  t('shared_gallery_origin'),
      screenshots: [
        `${staticsUrl}assets/editor-images/shared-gallery.png`,
        `${staticsUrl}assets/editor-images/mobile-shared-gallery.png`,
      ]
    }
  });

  var appToken;
  var sdk;

  function withDefaultPageProps(page) {
    return Object.assign({}, {
      numbers: {},
      showInLoginMenu: false,
      showInMemberMenu: true,
      social: true,
      socialHome: false,
      urlOverride: null
    }, page);
  }

  function getMembersAreaApplications(t, staticsUrl) {
    return [
      withDefaultPageProps(getAboutPage(t, staticsUrl)),
      withDefaultPageProps(getFollowersPage(t, staticsUrl)),
      withDefaultPageProps(getAllMembersPage(t, staticsUrl)),
      withDefaultPageProps(getNotificationsPage(t, staticsUrl)),
      withDefaultPageProps(getSettingsPage(t, staticsUrl)),
      withDefaultPageProps(getGalleryPage(t, staticsUrl))
    ];
  }

  const editorTranslation = new Translations();

  return {
    editorReady: async function (_editorSDK, _appToken, options) {
      const locale = await _editorSDK.info.getLanguage();
      const staticsUrl = parseStaticsUrlFromEditorScriptUrl(options.initialAppData.editorScriptUrl);
      await editorTranslation.init(locale, staticsUrl);

      const membersAreaApplications = getMembersAreaApplications(editorTranslation.t.bind(editorTranslation), staticsUrl);
      await integrateMembersAreaApps(membersAreaApplications);
      appToken = _appToken;
      sdk = _editorSDK;
    },
    getAppManifest: function () {
      return ({});
    },
    handleAction: function (args) {
      var type = args.type, payload = args.payload;
      try {
        switch (type) {
          case 'appInstalled':
            switch (payload.appDefinitionId) {
              case photoSharingAppDefId: {
                return sdk.pages.data.getAll(appToken)
                  .then(allPages => {
                    var filesPage = allPages.find(page => page.tpaPageId === 'Shared Gallery' && page.tpaApplicationId === photoSharingAppDefId);
                    return filesPage && sdk.document.pages.navigateTo(appToken, {pageLink: {type: 'PageLink', pageId: filesPage.id}});
                  })
                  .then(() => sdk.document.save());
              }
              default:
                return Promise.resolve()
            }
          default:
            // console.log(type, payload);
            return Promise.resolve();
        }
      }
      catch (e) {
        Promise.reject();
        throw e;
      }
    },
    getControllerPresets: function () {
      return Promise.resolve([]);
    },
    exports: {
    }
  };
}

const editorApp = withMembersArea(getSharedGalleryEditorApi());

export const editorReady = editorApp.editorReady;
export const handleAction = editorApp.handleAction;
export const onEvent = editorApp.onEvent;
export const getAppManifest = editorApp.getAppManifest;
export const getControllerPresets = editorApp.getControllerPresets;
